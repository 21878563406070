<div class="container">
  <div class="background"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
        <div class="card">
          <div class="card-header">
            <h3>For U Note Admin</h3>
          </div>
          <div class="card-form">
            <div class="form-row">
              <i class="material-icons">face</i>
              <md-input-container color="accent">
                <input type="text" mdInput placeholder="用户名" />
              </md-input-container>
            </div>
            <div class="form-row">
              <i class="material-icons">lock_outline</i>
              <md-input-container color="accent">
                <input type="password" mdInput placeholder="密码" />
              </md-input-container>
            </div>
          </div>
          <div class="card-footer">
            <button md-button (click)="loginBtn()">登录</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
